<template>
  <a-layout class="components-layout-demo-custom-trigger">
    <a-layout-sider breakpoint="lg" collapsed-width="0">
      <div class="logo">
        <img id="logo" v-if="sor && sor.file_name" :src="sor.file_url" />
        <a-avatar icon="user" v-else class="user_icon" />
        <a-row>
          <a-col :xs="{ span: 0 }" :md="24">
            {{ userName }}
          </a-col>
        </a-row>
      </div>
      <a-menu
        theme="dark"
        mode="inline"
        @select="menuItemChanged"
        :selectedKeys="selectedKeys"
      >
        <a-menu-item key="1">
          <router-link to="/admin/dashboard">
            <a-icon type="line-chart" />
            <span>Statistiques</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="2">
          <router-link to="/admin/adherents">
            <a-icon type="usergroup-add" />
            <span>Etat des inscrits</span>
            <a-badge
              :count="getAdherentEnAttenteCount"
              :numberStyle="{
                backgroundColor: '#009688',
                'margin-left': '15%',
              }"
            />
          </router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/admin/cotisation">
            <a-icon type="dollar" />
            <span>Etat des paiements</span>
            <a-badge
              :count="newPaiementsCount"
              :numberStyle="{
                backgroundColor: '#009688',
                'margin-left': '15%',
              }"
            />
          </router-link>
        </a-menu-item>

        <a-menu-item key="4">
          <router-link to="/admin/attestations">
            <a-icon type="copy" />
            <span>Etat des attestations</span>
            <a-badge
              :count="demandeAttestationsCount"
              :numberStyle="{
                backgroundColor: '#009688',
                'margin-left': '15%',
              }"
            />
          </router-link>
        </a-menu-item>
        <a-menu-item key="5">
          <router-link to="/admin/demandes">
            <a-icon type="form" />
            <span>Liste des demandes</span>
            <a-badge
              :count="demandeOuverturesCount"
              :numberStyle="{
                backgroundColor: '#009688',
                'margin-left': '15%',
              }"
            />
          </router-link>
        </a-menu-item>
        <a-sub-menu key="6">
          <span slot="title">
            <a-icon type="setting" />
            <span>Configuration</span>
          </span>
          <a-sub-menu key="7">
            <span slot="title">
              <a-icon type="highlight" />
              <span>Conventions</span>
            </span>
            <a-menu-item key="8">
              <router-link to="/admin/type_attestations">
                <a-icon type="file-add" />
                <span>Type des attestations</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="9">
              <router-link to="/admin/organismes">
                <a-icon type="home" />
                <span>Organismes</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>

          <a-sub-menu key="10">
            <span slot="title">
              <a-icon type="dollar" />
              <span>Paiements</span>
            </span>
            <a-menu-item key="11">
              <router-link to="/admin/exercices">
                <a-icon type="calendar" />
                <span>Exercices</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="12">
              <router-link to="/admin/type_paiements">
                <a-icon type="euro" />
                <span>Type des paiements</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="13">
            <router-link to="/admin/dossier_requis">
              <a-icon type="folder-open" />
              <span>Dossiers requis</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="14">
            <router-link to="/admin/type_demandes">
              <a-icon type="file-add" />
              <span>Type des demandes</span>
            </router-link>
          </a-menu-item>

          <a-menu-item key="15">
            <router-link to="/admin/rapports">
              <a-icon type="file-pdf" />
              <span>Rapports</span>
            </router-link>
          </a-menu-item>

          <a-menu-item key="16">
            <router-link to="/admin/messages-communications">
              <a-icon type="notification" />
              <span>Messages communications</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="17">
            <router-link to="/admin/page-accueil">
              <a-icon type="notification" />
              <span>Page Accueil</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="18">
            <router-link to="/admin/organisation/edit">
              <a-icon type="home" />
              <span>Organisation</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="19">
            <router-link to="/admin/journal">
              <a-icon type="solution" />
              <span>Journal d'utilisation</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>
        <!-- </a-sub-menu> -->
        <a-menu-item key="20">
          <router-link to="/admin/deleted-users">
            <a-icon type="delete" />
            <span>Les adhérents supp.</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="21">
          <router-link to="/admin/messagerie">
            <a-icon type="inbox" />
            <span>Messagerie</span>
            <a-badge
              :count="unreadMsgCount"
              :numberStyle="{
                backgroundColor: '#009688',
                'margin-left': '15%',
              }"
            />
          </router-link>
        </a-menu-item>
        <a-menu-item key="22">
          <router-link to="/admin/login">
            <a-icon type="logout" />
            <span>Déconnexion</span>
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-content class="content_layout">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import auth from "@/services/auth";
import _truncate from "lodash-es/truncate";
import _upperFirst from "lodash-es/upperFirst";

export default {
  name: "AdminHome",
  created() {
    this.admin = auth.getUser();
    this.$store.cache.dispatch("fetchOrganisation");
  },
  mounted() {
    this.selectElementFromSideBar();
    this.listenForEvents();
  },
  data() {
    return {
      visible: false,
      admin: null,
      selectedKeys: ["2"],
    };
  },
  computed: {
    ...mapGetters({
      unreadMsgCount: "getTotalUnreadMessages",
      getAdherentEnAttenteCount: "getAdherentEnAttenteCount",
      demandeAttestationsCount: "getDemandesAttestationsCount",
      demandeOuverturesCount: "getDemandesOuverturesCount",
      newPaiementsCount: "getNewPaiementsCount",
    }),
    ...mapState({
      sor: (state) => state.organisation.organisation,
    }),
    userName() {
      return _upperFirst(
        _truncate(this.get(this, "admin.full_name"), {
          length: 21,
          separator: " ",
        })
      );
    },
  },
  methods: {
    listenForEvents() {
      (window.Echo.options.auth = {
        headers: {
          Authorization: `Bearer ${this.getAuthorizationToken()}`,
        },
      }),
         
        window.Echo.channel(`admin.${this.admin.organisation_id}`).listen(
          "UserEmailVerified",
          (e) => {
            this.$_showSuccessMessage(
              `L'utilisateur ${e.user.nom_fr} ${e.user.prenom_fr} à confirmer son email`
            );
            this.getNewUsersCount();
          }
        ).listen(
          "NewPaiementCreated",
          (e) => {
            this.$_showSuccessMessage(
              `L'utilisateur ${e.user.nom_fr} ${e.user.prenom_fr} à effectuer un paiement`
            );
            this.getNewPaiementsCount();
          }
        ).listen(
          "NewDemandeAttestationCreated",
          (e) => {
            this.$_showSuccessMessage(
              `L'utilisateur ${e.user.nom_fr} ${e.user.prenom_fr} à effectuer un demande d'attestation`
            );
            this.getNewDemandeAttestationCount()
          }
        ).listen(
          "EmailSent",
          (e) => {
            this.$_showSuccessMessage(
              `L'utilisateur ${e.user.nom_fr} ${e.user.prenom_fr} à vous envoyer un email`
            );
            this.getUnreadMessagesCount()
          }
        );
    },
    selectElementFromSideBar() {
      if (localStorage.getItem("selectedAdminMenuKey")) {
        let selectedAdminMenuKey = localStorage.getItem("selectedAdminMenuKey");
        this.selectedKeys = [selectedAdminMenuKey];
      }
    },
    menuItemChanged({ key }) {
      this.selectedKeys = [key];
      localStorage.setItem("selectedAdminMenuKey", key);
    },
    handleCreate() {
      const form = this.$refs.profileForm.form;
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.update({ ...this.sor, ...values }).then(() => {
            form.resetFields();
            this.closeModal();
          });
        }
        this.showModal();
      });
    },
    closeModal() {
      this.visible = false;
    },
    showModal() {
      this.visible = true;
    },
    ...mapActions({
      update: "updateOrganisation",
      getNewUsersCount: "fetchNewَAdherents",
      getNewPaiementsCount: "fetchNewPaiementsCountCount",
      getNewDemandeAttestationCount: "fetchNewDemandeAttestationCount",
      getUnreadMessagesCount: "fetchUnreadMessagesCount",
    }),
  },
};
</script>
<style>
.content_layout {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: max-content;
  padding-bottom: 3.5rem;
}

.trigger {
  height: fit-content !important;
  color: white;
  text-align: right;
  margin-right: 20% !important;
  font-size: 1.7em;
}

.ant-layout {
  background-color: white !important;
}

.components-layout-demo-custom-trigger .trigger {
  padding-left: 70%;
  padding-right: 20%;
  padding-top: 0%;
}

.ant-form-item-label {
  text-align: start !important;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

#logo {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.my-img {
  width: auto;
  height: auto;
}
</style>
