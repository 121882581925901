const COUNT_URL = `/admin/fetch_dashboard_counts`;

export default {
  state: {
    requestQueue: new Map(),
    isRequesting: false,
    queueTimeout: undefined,
  },

  mutations: {
    addToCounterQueue(state, { key, resolver }) {
      state.requestQueue.set(key, resolver);
    },
    setCounterRequesting(state, status) {
      state.isRequesting = status;
    },
    clearCounterQueue(state) {
      state.requestQueue.clear();
    },
  },

  actions: {
    async processCounterQueue({ state, commit, getters }) {
      if (state.isRequesting || state.requestQueue.size === 0) return;

      commit("setCounterRequesting", true);

      try {
        const keysToFetch = Array.from(state.requestQueue.keys());
        const params = keysToFetch.reduce((acc, k) => {
          acc[k] = 1;
          return acc;
        }, {});
        const response = await getters.authenticatedAxios.get(COUNT_URL, {params});
        const data = response.data;

        keysToFetch.forEach((key) => {
          if (data[key]) {
            const resolver = state.requestQueue.get(key);
            if (resolver) resolver(data[key]);
          }
        });
      } finally {
        commit("clearCounterQueue");
        commit("setCounterRequesting", false);
      }
    },
    async enqueueToCounter({ commit, dispatch }, { key, resolver }) {
      commit("addToCounterQueue", { key, resolver });

      this.queueTimeout = setTimeout(() => {
        dispatch("processCounterQueue");
      }, 50);
    },
  },
};
