<template>
    <a-modal
            :title="title"
            :visible="visible"
            @cancel="handleCancel"
            okText="Valider"
            @ok="handleOk"
            :okButtonProps="{ props: { htmlType: 'submit' } }"
    >
        <a-form
                :layout="formLayout"
                id="visite_form"
                name="visite_form"
                class="adherent_form"
                :form="form"
        >
            <a-row>
                <a-col :span="18">
                    <a-form-item v-bind="formItemLayout" label="Type">
                        <a-select
                                placeholder="Choisir un type"
                                v-decorator="[
                                                'type_visite_id',
                                                {
                                                  rules: [
                                                    { required: true, message: 'Type  est obligatoire!' },
                                                  ],
                                                },
                                              ]"
                        >
                            <a-select-option :key="typeVisite.id" v-for="typeVisite in typeVisites">
                                {{ typeVisite.nom_fr }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="18">
                    <a-form-item v-bind="formItemLayout" label="Dt. visite :">
                        <a-date-picker
                                style="width: 100%;"
                                :format="dateFormat"
                                v-decorator="[
                'dt_reel_visite',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Date visite est obligatoire!',
                    },
                  ],
                },
              ]"
                        />
                    </a-form-item>
                </a-col>
                <a-col :span="18">
                <a-form-item v-bind="formItemLayout" label="Docteur">
                    <a-input
                    placeholder=""
                    v-decorator="[
                        'visiter_par',
                        {
                        rules: [
                            {
                            required: false,
                            message: 'Ce champ est obligatoire!',
                            },
                        ],
                        },
                    ]"
                    />
                </a-form-item>  
                </a-col>
                <a-col :span="18">
                    <a-form-item v-bind="formItemLayout" label="Wilaya">
                        <a-input
                        placeholder=""
                        v-decorator="[
                            'wilaya',
                            {
                            rules: [
                                {
                                required: false,
                                message: 'Ce champ est obligatoire!',
                                },
                            ],
                            },
                        ]"
                        />
                    </a-form-item>  
                </a-col>
                <a-col :span="18">
                    <a-form-item v-bind="formItemLayout" label="Ajouter crédit :">
                        <a-checkbox
                                :checked="isAddCreditChecked"
                                v-decorator="['is_credit_checked']"
                                @change="handleCreditCheckedChange"
                        >
                        </a-checkbox>
                    </a-form-item>
                </a-col>
                <div v-if="isAddCreditChecked">
                    <a-col :span="18">
                        <a-form-item label='Montant' v-bind="formItemLayout">
                            <a-input-number
                                    style="width: 90%"
                                    v-decorator="[
                            'montant',
                            {
                              rules: [
                                { required: isAddCreditChecked, message: 'Montant est obligatoire' },
                              ],
                            },
                          ]"
                                    placeholder="Entrer le Montant"
                            />
                            Montant à payer : <b>{{get(demande,'exercice_type_reglement.montant') | currency}}</b>
                        </a-form-item>
                    </a-col>
                    <a-col :span="18">
                        <a-form-item v-bind="formItemLayout" label="N° du reçu">
                            <a-input
                                    style="width: 90%"
                                    v-decorator="[
                            'num_recu',
                            {
                              rules: [
                                { required: isAddCreditChecked, message: 'Numéro est obligatoire' },
                              ],
                            },
                          ]"
                                    placeholder="Entrer le numéro reçus"
                            />
                        </a-form-item>
                    </a-col>
                </div>
            </a-row>
        </a-form>
        <template slot="footer">
            <a-button
                    key="submit"
                    type="primary"
                    :loading="processing"
                    @click="handleOk"
            >Valider
            </a-button
            >
        </template>
    </a-modal>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "VisiteForm",
  created() {
    this.getAdditioanlData();
  },
  mounted() {
    this.fillTheForm();
  },
  data() {
    return {
      formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 8},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 14},
                    },
                },
                isAddCreditChecked: true,
                form: this.$form.createForm(this),

            };
        },
        props: {
            processing: {type: Boolean, default: () => false},
            visible: {type: Boolean, default: () => false},
            visite: {type: Object},
            demande: {type : Object}
        },
        computed: {
            ...mapGetters({
                typeVisites: 'getTypeVisites',
            }),
            title() {
                return this.visite ? 'Modifier visite ' : 'Ajouter visite';
            },
        },
        methods: {
            getAdditioanlData(){
                this.$store.cache.dispatch("fetchTypeVisites");
            },
            handleCreditCheckedChange(e) {
                this.isAddCreditChecked = e.target.checked;
            },
            handleClose() {
                this.$emit("close");
            },
            handleCancel() {
                this.$emit("close");
            },
            handleOk() {
                this.$emit("create");
            },
            fillTheForm() {
                if (this.visite) {
                    this.$nextTick(() => {
                        this.form.setFieldsValue(this.visite);
                        this.form.setFieldsValue({dt_reel_visite: this.momentDate(this.visite.dt_reel_visite)});
                        this.form.setFieldsValue({type_visite_id: this.get(this.visite, 'type.id')});
                        this.isAddCreditChecked = !!this.visite.montant;
                    });
                    return
                }
                this.isAddCreditChecked = false;
            }
        },
    };
</script>
