var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticStyle: { "margin-bottom": "4%" } }, [
        _vm._v("Etat des paiements")
      ]),
      _c("PaiementsListeWithFilters", {
        attrs: {
          dataSource: _vm.cotisations,
          processing: _vm.processing,
          excercices: _vm.excercices,
          secteurs: _vm.secteurs,
          wilayas: _vm.wilayas,
          isAdmin: true,
          modes_reglement: _vm.modes_reglement,
          types_reglements: _vm.types_reglements,
          somme: _vm.somme
        },
        on: {
          change: function($event) {
            return _vm.searchPaiement($event)
          },
          exercice_change: function($event) {
            return _vm.handleExerciceChange($event)
          },
          paiement_change: function($event) {
            return _vm.handlePaiementChange($event)
          },
          type_reglement_change: function($event) {
            return _vm.handleTypeReglementChange($event)
          },
          etat_secteur_activite_change: function($event) {
            return _vm.handleSecteurActviteChange($event)
          },
          wilaya_change: function($event) {
            return _vm.handleWilayaChange($event)
          },
          date_range_change: function($event) {
            return _vm.dateRangeChange($event)
          }
        }
      }),
      _vm.cotisations.length
        ? _c("a-pagination", {
            staticStyle: { "margin-top": "1%" },
            attrs: {
              current: _vm.pagination.current,
              total: _vm.pagination.total,
              pageSize: _vm.pagination.pageSize,
              showSizeChanger: true
            },
            on: {
              change: _vm.onPaginationChange,
              showSizeChange: _vm.onPageSizeChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }