<template>
  <a-cascader :field-names="{ label: 'nom_affichage', value: 'value', children: 'exercices' }" :options="etats"
    :placeholder="placeholder" @change="handlePaiementChange" style="width: 100%;"
    :value="selectedArray" />
</template>
<script>
export default {
  name: "EtatPaiementList",
  props: { selectedValue: { type: Array }, exercices: Array, placeholder: String },
  data() {
    return {
      etats: [],
    }
  },
  mounted() {
    this.etats[0] = {
      value: 0,
      nom_affichage: 'Tous',
    };
    this.etats[1] = {
      value: 1,
      nom_affichage: 'Liste des adhérents à jour',
    };
    this.etats[2] = {
      value: 2,
      nom_affichage: 'Liste des adhérents non à jour',
      exercices: this.exercices.map(this.getExerciceSubChildObject),
    };
  },
  methods: {
    handlePaiementChange(selectedItem) {
      this.$emit("etat_paiement_change", {
        value: selectedItem[0] ? selectedItem[0] : undefined,
        exercice: selectedItem[1],
      });
    },
    getExerciceSubChildObject(e) {
      const annee = new Date(e.annee).getFullYear()
      return {
        value: annee,
        nom_affichage: annee,
      };
    }
  },
  watch: {
    exercices() {
      this.etats[2].exercices = this.exercices.map(this.getExerciceSubChildObject);
    }
  },
  computed: {
    selectedArray() {
      const selectedOption = this.selectedValue[0];
      if (isNaN(selectedOption)) 
        return [];

      if (selectedOption != 2) 
        return [selectedOption];

      return [selectedOption, this.selectedValue[1]]
    }
  },
};
</script>
