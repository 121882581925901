<template>
  <a-select
    placeholder="Etat professionel"
    :allowClear="true"
    :mode="mode"
    :default-value="mode == 'default' ? selectedLabel : etatProfessionnel"
    @change="handleEtatProfessionnelChange"
    style="width: 100%"
  >
    <a-select-option value="0">Tous</a-select-option>
    <a-select-option v-for="etat in etatsPossibles" :key="etat.id">
      {{ etat.nom_fr }}
    </a-select-option>
  </a-select>
</template>
<script>
export default { 
  name: "EtatProfessionalList",
  props: { 
      etatsPossibles: Array, 
      etatProfessionnel: [Array, Number], 
      mode : {type : String, default : () => 'default'},
      selectedValue: {type : Number},
    },
  methods: {
    handleEtatProfessionnelChange(selectedItem) {
      this.$emit("etat_professionnel_change", selectedItem);
    }
  },
  computed: {
    selectedLabel() {
      if (isNaN(this.selectedValue)) 
        return undefined;

      const etats = this.etatsPossibles.filter(e => e.id == this.selectedValue)

      if (etats.length == 0)
        return undefined;
        
      return etats[0].nom_fr;
    },
  },
};
</script>
