<template>
  <span>
    <span v-if="isPayeAjour(balance)">
      Vous êtes à jour jusqu'à l'année {{nextYearExercice}}
    </span>
    <span v-else-if="exercices.length > 0">
      Vous devez régulariser vos cotisations pour la période
      <b v-bind:style="{ color: RED_COLOR }"> {{ exercices }}. </b>
    </span>
    <span v-else>
      Vous êtes à jour mais vous avez des dettes à régler
    </span>
  </span>
</template>
<script>
import moment from "moment";
export default {
  name: "ExercicesToPayIndicator",
  props: {
    balance: { type: Number, default: () => 0 },
    exercices: { type: Array },
  },
  computed :{
    nextYearExercice(){
       return  moment().add(1, 'year').year();
    }
  }
};
</script>

