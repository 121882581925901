var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MemberForm", {
        key: _vm.memberFormComponentKey,
        ref: "memberForm",
        attrs: {
          membre: _vm.selectedMember,
          processing: _vm.memberFormProcessing,
          visible: _vm.memberFormModalVisible
        },
        on: {
          cancel: _vm.hideDecisionForm,
          close: _vm.hideDecisionForm,
          submit: _vm.handleMemberFormSubmission
        }
      }),
      _c("EventForm", {
        key: _vm.eventFormComponentKey,
        ref: "eventForm",
        attrs: {
          evenement: _vm.selectedEvent,
          processing: _vm.eventFormProcessing,
          visible: _vm.eventFormModalVisible
        },
        on: {
          cancel: _vm.hideEventForm,
          close: _vm.hideEventForm,
          submit: _vm.handleEventFormSubmission
        }
      }),
      _c("DocumentForm", {
        key: _vm.documentFormComponentKey,
        ref: "documentForm",
        attrs: {
          document: _vm.selectedDocument,
          processing: _vm.documentFormProcessing,
          visible: _vm.documentFormModalVisible
        },
        on: {
          cancel: _vm.hideDocumentForm,
          close: _vm.hideDocumentForm,
          submit: _vm.handleDocumentFormSubmission
        }
      }),
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "Messages communications " } },
            [
              _c(
                "a-spin",
                { attrs: { spinning: _vm.processing } },
                [
                  _c(
                    "a-form",
                    {
                      attrs: {
                        id: "add_form_form",
                        form: _vm.form,
                        layout: _vm.myFormLayout,
                        name: "add_form_form"
                      }
                    },
                    [
                      _c(
                        "a-row",
                        {
                          attrs: {
                            align: "middle",
                            justify: "space-around",
                            type: "flex"
                          }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c("p", [_vm._v("Logo :")]),
                              _c("UploaderWithPreview", {
                                attrs: {
                                  action: _vm.organisationLogoUploadUrl(
                                    _vm.accueil.id
                                  ),
                                  bearer: _vm.getAuthorizationToken(),
                                  "img-url": _vm.accueil.logo_url,
                                  record: _vm.accueil,
                                  "show-uploaded-img": _vm.showUploadedImg(),
                                  name: "file"
                                },
                                on: {
                                  delete_file: function($event) {
                                    return _vm.deleteFile($event)
                                  },
                                  upload_success: function($event) {
                                    return _vm.onUploadSuccess()
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Accueil :" } },
                                [
                                  _c("vue-editor", {
                                    model: {
                                      value: _vm.accueil.message_accueil,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.accueil,
                                          "message_accueil",
                                          $$v
                                        )
                                      },
                                      expression: "accueil.message_accueil"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "A propos :" } },
                                [
                                  _c("vue-editor", {
                                    model: {
                                      value: _vm.accueil.a_propos,
                                      callback: function($$v) {
                                        _vm.$set(_vm.accueil, "a_propos", $$v)
                                      },
                                      expression: "accueil.a_propos"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Horaires :" } },
                                [
                                  _c("vue-editor", {
                                    model: {
                                      value: _vm.accueil.horaires,
                                      callback: function($$v) {
                                        _vm.$set(_vm.accueil, "horaires", $$v)
                                      },
                                      expression: "accueil.horaires"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Mot du président :" } },
                                [
                                  _c("vue-editor", {
                                    model: {
                                      value: _vm.accueil.mot_de_president,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.accueil,
                                          "mot_de_president",
                                          $$v
                                        )
                                      },
                                      expression: "accueil.mot_de_president"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Afficher les membres:" } },
                                [
                                  _c("a-switch", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "display_members",
                                          {
                                            rules: [
                                              {
                                                required: false,
                                                message:
                                                  "Ce champ est bligatoire!"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                    'display_members',\n                                    {\n                                    rules: [\n                                        {\n                                        required: false,\n                                        message: 'Ce champ est bligatoire!',\n                                        },\n                                    ],\n                                    },\n                                ]"
                                      }
                                    ],
                                    attrs: {
                                      checked: _vm.accueil.display_members
                                    },
                                    on: { change: _vm.onMembersDisplayToggle }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Afficher A propos :" } },
                                [
                                  _c("a-switch", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "display_about",
                                          {
                                            rules: [
                                              {
                                                required: false,
                                                message:
                                                  "Ce champ est bligatoire!"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                    'display_about',\n                                    {\n                                    rules: [\n                                        {\n                                        required: false,\n                                        message: 'Ce champ est bligatoire!',\n                                        },\n                                    ],\n                                    },\n                                ]"
                                      }
                                    ],
                                    attrs: {
                                      checked: _vm.accueil.display_about
                                    },
                                    on: { change: _vm.onAboutDisplayToggle }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: { label: "Afficher les evenements:" }
                                },
                                [
                                  _c("a-switch", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "display_events",
                                          {
                                            rules: [
                                              {
                                                required: false,
                                                message:
                                                  "Ce champ est bligatoire!"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                    'display_events',\n                                    {\n                                    rules: [\n                                        {\n                                        required: false,\n                                        message: 'Ce champ est bligatoire!',\n                                        },\n                                    ],\n                                    },\n                                ]"
                                      }
                                    ],
                                    attrs: {
                                      checked: _vm.accueil.display_events
                                    },
                                    on: { change: _vm.onEventsDisplayToggle }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { lg: 14, span: 13, xs: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Afficher les documents:" } },
                                [
                                  _c("a-switch", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "display_documents",
                                          {
                                            rules: [
                                              {
                                                required: false,
                                                message:
                                                  "Ce champ est bligatoire!"
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                                    'display_documents',\n                                    {\n                                    rules: [\n                                        {\n                                        required: false,\n                                        message: 'Ce champ est bligatoire!',\n                                        },\n                                    ],\n                                    },\n                                ]"
                                      }
                                    ],
                                    attrs: {
                                      checked: _vm.accueil.display_documents
                                    },
                                    on: { change: _vm.onDocumentsDisplayToggle }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { offset: 2, span: 2 } },
                            [
                              _c(
                                "a-form-item",
                                _vm._b(
                                  {},
                                  "a-form-item",
                                  _vm.tailFormItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        loading: _vm.processing,
                                        htmlType: "submit",
                                        type: "primary"
                                      },
                                      on: {
                                        click:
                                          _vm.handleMessageCommunicationsUpdate
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Sauvergarder\n                                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { "force-render": "", tab: "Membres " } },
            [
              _c(
                "a-row",
                { attrs: { justify: "start", type: "flex" } },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "editable-add-btn mb-2",
                          attrs: { type: "primary" },
                          on: { click: _vm.addNewMember }
                        },
                        [_vm._v("Ajouter un membre\n                    ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.membres.length
                ? _c("a-table", {
                    attrs: {
                      columns: _vm.membersColumns,
                      dataSource: _vm.membres,
                      bordered: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "row_index",
                          fn: function(text, record, index) {
                            return [_c("p", [_vm._v(_vm._s(index + 1))])]
                          }
                        },
                        {
                          key: "full_name",
                          fn: function(text, record) {
                            return [
                              record.photo
                                ? _c("a-avatar", {
                                    attrs: {
                                      slot: "avatar",
                                      src: record.photo_url
                                    },
                                    slot: "avatar"
                                  })
                                : _c("a-avatar", [
                                    _vm._v(_vm._s(record.full_name[0]))
                                  ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "1rem" } },
                                [_vm._v(_vm._s(record.full_name))]
                              )
                            ]
                          }
                        },
                        {
                          key: "upload",
                          fn: function(text, record) {
                            return [
                              record.photo
                                ? _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Visualiser")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass: "action_icons",
                                        attrs: { type: "search" },
                                        on: {
                                          click: function($event) {
                                            return _vm.previewDocument(
                                              record.photo
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c("Uploader", {
                                attrs: {
                                  action: _vm.uploadUrl(record.id),
                                  bearer: _vm.getAuthorizationToken(),
                                  record: record,
                                  name: "file"
                                },
                                on: {
                                  delete_file: function($event) {
                                    return _vm.deleteFile($event)
                                  },
                                  upload_success: function($event) {
                                    return _vm.onUploadSuccess()
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "operation",
                          fn: function(text, record) {
                            return [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title:
                                      "Etes-vous sûr de vouloir supprimer cette ligne ?"
                                  },
                                  on: {
                                    confirm: function() {
                                      return _vm.onMemberDelete(record.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Suppression")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass: "action_icons",
                                        attrs: {
                                          theme: "twoTone",
                                          twoToneColor: "#eb2f96",
                                          type: "delete"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("span", [_vm._v("Edition")])
                                  ]),
                                  _c("a-icon", {
                                    staticClass: "action_icons",
                                    attrs: {
                                      theme: "twoTone",
                                      twoToneColor: "#52c41a",
                                      type: "edit"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleMemberEdit(record)
                                      }
                                    }
                                  })
                                ],
                                2
                              )
                            ]
                          }
                        },
                        {
                          key: "etat",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    Number(record.etat) === _vm.VISIBLE
                                      ? "Visible"
                                      : "Cachée"
                                  ) +
                                  "\n                "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1062024372
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "Evenements" } },
            [
              _c(
                "a-row",
                { attrs: { justify: "start", type: "flex" } },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "editable-add-btn mb-2",
                          attrs: { type: "primary" },
                          on: { click: _vm.addNewEvent }
                        },
                        [_vm._v("Ajouter un evenement\n                    ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.events.length
                ? _c("a-table", {
                    attrs: {
                      columns: _vm.eventsColumns,
                      dataSource: _vm.events,
                      bordered: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "row_index",
                          fn: function(text, record, index) {
                            return [_c("p", [_vm._v(_vm._s(index + 1))])]
                          }
                        },
                        {
                          key: "upload",
                          fn: function(text, record) {
                            return [
                              _vm._l(record.logo_url, function(image, idx) {
                                return _c(
                                  "a-col",
                                  {
                                    directives: [
                                      {
                                        name: "key",
                                        rawName: "v-key",
                                        value: image,
                                        expression: "image"
                                      }
                                    ],
                                    key: idx
                                  },
                                  [
                                    _c(
                                      "a-row",
                                      [
                                        _c(
                                          "a-tooltip",
                                          { attrs: { placement: "top" } },
                                          [
                                            _c("template", { slot: "title" }, [
                                              _c("span", [_vm._v("Visualiser")])
                                            ]),
                                            _c("a-icon", {
                                              staticClass: "action_icons",
                                              attrs: { type: "search" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.previewDocument(
                                                    image
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          2
                                        ),
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title:
                                                "Etes-vous sûr de vouloir supprimer cette photo ?"
                                            },
                                            on: {
                                              confirm: function() {
                                                return _vm.onImageDelete(
                                                  record.id,
                                                  idx
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "a-tooltip",
                                              { attrs: { placement: "top" } },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Suppression")
                                                    ])
                                                  ]
                                                ),
                                                _c("a-icon", {
                                                  staticClass: "action_icons",
                                                  attrs: {
                                                    theme: "twoTone",
                                                    twoToneColor: "#eb2f96",
                                                    type: "delete"
                                                  }
                                                })
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _c("Uploader", {
                                attrs: {
                                  action: _vm.eventLogoUploadUrl(record.id),
                                  bearer: _vm.getAuthorizationToken(),
                                  record: record,
                                  name: "file"
                                },
                                on: {
                                  delete_file: function($event) {
                                    return _vm.deleteFile($event)
                                  },
                                  upload_success: function($event) {
                                    return _vm.onUploadSuccess()
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "operation",
                          fn: function(text, record) {
                            return [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title:
                                      "Etes-vous sûr de vouloir supprimer cette ligne ?"
                                  },
                                  on: {
                                    confirm: function() {
                                      return _vm.onEventDelete(record.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Suppression")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass: "action_icons",
                                        attrs: {
                                          theme: "twoTone",
                                          twoToneColor: "#eb2f96",
                                          type: "delete"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("span", [_vm._v("Edition")])
                                  ]),
                                  _c("a-icon", {
                                    staticClass: "action_icons",
                                    attrs: {
                                      theme: "twoTone",
                                      twoToneColor: "#52c41a",
                                      type: "edit"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEventEdit(record)
                                      }
                                    }
                                  })
                                ],
                                2
                              )
                            ]
                          }
                        },
                        {
                          key: "etat",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    Number(record.etat) === _vm.VISIBLE
                                      ? "Visible"
                                      : "Cachée"
                                  ) +
                                  "\n                "
                              )
                            ]
                          }
                        },
                        {
                          key: "expandedRowRender",
                          fn: function(record) {
                            return _c("div", { staticStyle: { margin: "0" } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(record.contenu) +
                                  "\n                "
                              )
                            ])
                          }
                        }
                      ],
                      null,
                      false,
                      4063702987
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "4", attrs: { tab: "Contacts" } },
            [
              _c(
                "a-spin",
                { attrs: { spinning: _vm.contactDataProcessing } },
                [
                  _c(
                    "a-row",
                    { attrs: { justify: "start", type: "flex" } },
                    [
                      _c(
                        "a-col",
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "editable-add-btn mb-2",
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.getContacts()
                                }
                              }
                            },
                            [_vm._v("Actualiser\n                    ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-table", {
                    attrs: {
                      columns: _vm.contactsColumns,
                      dataSource: _vm.contacts,
                      bordered: "",
                      expandRowByClick: true
                    },
                    on: { expand: _vm.onContactRowExpand },
                    scopedSlots: _vm._u([
                      {
                        key: "row_index",
                        fn: function(text, record, index) {
                          return [
                            _vm.isNewContact(record)
                              ? _c("a-badge", {
                                  attrs: { status: "processing" }
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n                    " +
                                _vm._s(index + 1) +
                                "\n                "
                            )
                          ]
                        }
                      },
                      {
                        key: "etat",
                        fn: function(text, record) {
                          return [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  Number(record.etat) === _vm.VISIBLE
                                    ? "Visible"
                                    : "Cachée"
                                ) +
                                "\n                "
                            )
                          ]
                        }
                      },
                      {
                        key: "expandedRowRender",
                        fn: function(record) {
                          return _c("div", { staticStyle: { margin: "0" } }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(record.message) +
                                "\n                "
                            )
                          ])
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "5", attrs: { tab: "Documents" } },
            [
              _c(
                "a-row",
                { attrs: { justify: "start", type: "flex" } },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "editable-add-btn mb-2",
                          attrs: { type: "primary" },
                          on: { click: _vm.showDocumentForm }
                        },
                        [_vm._v("Ajouter\n                    ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-table", {
                attrs: {
                  columns: _vm.documentsColumns,
                  dataSource: _vm.documents,
                  bordered: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "row_index",
                    fn: function(text, record, index) {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(index + 1) +
                            "\n                "
                        )
                      ]
                    }
                  },
                  {
                    key: "etat",
                    fn: function(text, record) {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              Number(record.etat) === _vm.VISIBLE
                                ? "Visible"
                                : "Cachée"
                            ) +
                            "\n                "
                        )
                      ]
                    }
                  },
                  {
                    key: "date",
                    fn: function(text, record) {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.date(record.created_at)) +
                            "\n                "
                        )
                      ]
                    }
                  },
                  {
                    key: "upload",
                    fn: function(text, record) {
                      return [
                        record.file_name
                          ? _c(
                              "a-tooltip",
                              { attrs: { placement: "top" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("Visualiser")])
                                ]),
                                _c("a-icon", {
                                  staticClass: "action_icons",
                                  attrs: { type: "search" },
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDocument(
                                        record.file_url
                                      )
                                    }
                                  }
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _c("Uploader", {
                          attrs: {
                            action: _vm.documentToDownloadUploadUrl(record.id),
                            bearer: _vm.getAuthorizationToken(),
                            record: record,
                            name: "file"
                          },
                          on: {
                            delete_file: function($event) {
                              return _vm.deleteFile($event)
                            },
                            upload_success: function($event) {
                              return _vm.onDocumentsUploadSuccess()
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "operation",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title:
                                "Etes-vous sûr de vouloir supprimer cette ligne ?"
                            },
                            on: {
                              confirm: function() {
                                return _vm.onDocumentDelete(record.id)
                              }
                            }
                          },
                          [
                            _c(
                              "a-tooltip",
                              { attrs: { placement: "top" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("Suppression")])
                                ]),
                                _c("a-icon", {
                                  staticClass: "action_icons",
                                  attrs: {
                                    theme: "twoTone",
                                    twoToneColor: "#eb2f96",
                                    type: "delete"
                                  }
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-tooltip",
                          { attrs: { placement: "top" } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("span", [_vm._v("Edition")])
                            ]),
                            _c("a-icon", {
                              staticClass: "action_icons",
                              attrs: {
                                theme: "twoTone",
                                twoToneColor: "#52c41a",
                                type: "edit"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleDocumentEdit(record)
                                }
                              }
                            })
                          ],
                          2
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }