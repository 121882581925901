<template>
  <a-select
    :defaultValue="selectedLabel"
    :allowClear="true"
    @change="handleEtatInscriptionChange"
    style="width: 100%"
    placeholder="Etat inscription"
  >
    <a-select-option value="">Tous</a-select-option>
    <a-select-option v-for="etat in localEtats" :key="etat.key">
        {{ etat.value }}
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  name: "EtatInscriptionList",
  props: { localEtats: Array, selectedValue : Number },
  methods: {
    handleEtatInscriptionChange(selectedItem) {
      this.$emit("etat_inscription_change", selectedItem);
    }
  },
  computed: {
    selectedLabel() {
      if (isNaN(this.selectedValue)) 
        return undefined;

      const etats = this.localEtats.filter(e => e.key == this.selectedValue)

      if (etats.length == 0)
        return undefined;
        
      return etats[0].value;
    },
  },
};
</script>
