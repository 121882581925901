import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import message from "ant-design-vue/lib/message";
import moment from "moment";
import _get from "lodash-es/get";
import "ant-design-vue/dist/antd.less";
import auth from "@/services/auth";
import _forEach from "lodash-es/forEach";
import Echo from "laravel-echo"
import * as Sentry from "@sentry/vue";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

Vue.use(Antd);
Vue.config.productionTip = false;
Vue.config.devtools = true;
//--------------------------------- FILTERS ---------------------------------
Vue.filter("currency", value =>
    new Intl.NumberFormat("fr-FR", { style: "currency", currency: "DZD" }).format(
        value
    )
);
Vue.filter("date", date => (date ? moment(date).format("DD/MM/YYYY") : ""));
Vue.filter("year", date => (date ? moment(date).format("YYYY") : ""));
//---------------------------------Notifcation------------------
message.config({
    maxCount: 1
});
//--------------------------------- MIXINS ---------------------------------


window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'app-key',
    wsHost: window.location.hostname,
    authEndpoint: `${process.env.VUE_APP_ROOT_API_HTTP + window.location.hostname + process.env.VUE_APP_ROOT_API_PORT}/broadcasting/auth`,
    wsPort: 6001,
    wssPort: 6001,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});

const pusher = window.Echo.connector.pusher;
pusher.connection.bind('state_change', (states) => {
    if (states.current === 'unavailable' && states.previous === 'connecting') {
        pusher.connection.disconnect();
    }
});

Sentry.init({
    Vue,
    dsn: "https://72a46c1c15e441a48511a76f3bc93df6@o1080411.ingest.sentry.io/4503921778819072",
    integrations: [
      new CaptureConsoleIntegration({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "sor-cd.com", /^\//],
        levels:['warn', 'error'],
      }),
    ],
    tracesSampleRate: 0.2,
    logErrors: true,
  });

Vue.mixin({
    methods: {
        $_throwAnError(message, duration) {
            const defaultDuration = 3;
            let _duration = duration ? duration : defaultDuration;
            let _message = message ? message : "Une erreur est survenue";
            this.$message.error(_message, _duration);
        },
        $_showSuccessMessage(message, duration) {
            const defaultDuration = 3;
            let _duration = duration ? duration : defaultDuration;
            let _message = message ? message : "Opération effectuer avec success";
            this.$message.success(_message, _duration);
        },
        momentDate(date) {
            return date ? moment(date) : '';
        },
        date(date, format = "DD/MM/YYYY") {
            return date ? this.formatDate(moment(date), format) : "";
        },
        now(format = "DD/MM/YYYY") {
            return this.formatDate(moment(), format);
        },
        formatDate(momentDate, format = "DD/MM/YYYY") {
            return momentDate.format(format);
        },
        disabledFutureDates(current) {
            // Can not select days after today and today
            return current && current >= moment();
        },
        formatFields(destination, source, fields) {
            _forEach(fields, (value) => {
                if (source[value]) {
                    // ${source[value]}`);
                    destination[value] = this.formatDate(source[value], "YYYY-MM-DD");
                }
            });
        },
        get(object, path, defaultValue = "") {
            return _get(object, path, defaultValue);
        },
        isHomme(sexe) {
            return this.HOMME === Number(sexe);
        },
        isFemme(sexe) {
            return this.FEMME === Number(sexe);
        },
        isMarie(situation) {
            return this.MARIE === Number(situation);
        },
        isVeuf(situation) {
            return this.VEUF === Number(situation);
        },
        isUserPaiementSettled(user) {
            return (
                this.isPayeAjour(user.etat_paiement.balance) && this.isUserAccepter(user.etat)
            );
        },
        isPayeAjour(balance) {
            return balance <= 0;
        },
        isPaiementValide(etat) {
            return !!etat;
        },
        isUserNonSoumis(etat) {
            return Number(etat) === this.ETAT_ADHERANT_PAS_ENCORE_SOUMIS;
        },
        isUserEnAttente(etat) {
            return Number(etat) === this.ETAT_ADHERANT_EN_ATTENT;
        },
        isUserAccepter(etat) {
            return Number(etat) === this.ETAT_ADHERANT_ACCEPTER;
        },
        isUserRefuser(etat) {
            return Number(etat) === this.ETAT_ADHERANT_REFUSER;
        },
        isUserStatuProfessActive(etat) {
            return Number(etat) === this.ADHERANT_STATU_PROFESS_ACTIVE;
        },
        isUserStatuProfessArretActivite(etat) {
            return Number(etat) === this.ADHERANT_STATU_PROFESS_ARRET_ACTIVITE;
        },
        isUserStatuProfessRetraiter(etat) {
            return Number(etat) === this.ADHERANT_STATU_PROFESS_RETRAITER;
        },
        isEtatDemandeOuvertureEnAttente(etat) {

            return Number(etat) === this.ETAT_DEMANDE_OUVERTURE_EN_ATTENTE;
        },
        isEtatDemandeOuvertureEnTraitement(etat) {
            return Number(etat) === this.ETAT_DEMANDE_OUVERTURE_TRAIETEMENT;
        },
        isEtatDemandeOuvertureAccepter(etat) {
            return Number(etat) === this.ETAT_DEMANDE_OUVERTURE_ACCEPTER;
        },
        isEtatDemandeOuvertureRefuser(etat) {
            return Number(etat) === this.ETAT_DEMANDE_OUVERTURE_REFUSE;
        },
        isDemandeOuvertureTransfert(type) {
            return type === 'TRS';
        },
        isDemandeOuvertureCabinetGroupe(type) {
            return type === 'CG';
        },
        isDemandeOuvertureCabinet(type) {
            return type === 'OC';
        },
        hasConj(nom_conj) {
            return !!nom_conj;
        },
        clearAllTimeouts() {
            clearInterval(Window.newAdherentsEntriesTimeout);
            clearInterval(Window.newDemandesAttestationEntriesTimeout);
            clearInterval(Window.newDemandesOuverturesEntriesTimeout);
            clearInterval(Window.newMessagesEntriesTimeout);
            clearInterval(Window.userNewMessagesEntriesTimeout);
        },
        previewDocument(link) {
            if (!link) {
                return
            }
            window.open(`${link}`, "_blank")
        },
        getAuthorizationToken() {
            return auth.getAuthorization();
        },
        getRandomInt() {
            return Math.floor(Math.random() * 10 + 1);
        },
        checkDefaultValue(defaultValue, callback, value, message) {
            if (defaultValue === value) {
                callback(message);
                return;
            }
            callback();
        },
        filterInput(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        }
    },
    computed: {
        editMode() {
            return this.$route.params["op"] && this.$route.params["op"] === "edit";
        }
    },
    mounted() {},
    data() {
        return {
            SENT_BY_ADMIN: 0,
            SENT_BY_ADHERANT: 1,
            A_SOUMIE_DOSSIER: 1,
            NO_SOUMIS_DOSSIER: 0,

            ETAT_ADHERANT_PAS_ENCORE_SOUMIS: 0,
            ETAT_ADHERANT_EN_ATTENT: 1,
            ETAT_ADHERANT_ACCEPTER: 2,
            ETAT_ADHERANT_REFUSER: 3,
            ETAT_ADHERANT_ALL: 4,

            ETAT_DEMANDE_OUVERTURE_EN_ATTENTE: 1,
            ETAT_DEMANDE_OUVERTURE_TRAIETEMENT: 2,
            ETAT_DEMANDE_OUVERTURE_ACCEPTER: 3,
            ETAT_DEMANDE_OUVERTURE_REFUSE: 4,

            ETAT_DEMANDE_ATTESTATION_EN_ATTENTE: 0,
            ETAT_DEMANDE_ATTESTATION_REFUSER: 1,
            ETAT_DEMANDE_ATTESTATION_ACCEPTER: 2,

            ADHERANT_STATU_PROFESS_ACTIVE: 1,
            ADHERANT_STATU_PROFESS_ARRET_ACTIVITE: 2,
            ADHERANT_STATU_PROFESS_RETRAITER: 3,

            ADHERANT_SECTEUR_PRIVE: 1,
            ADHERANT_SECTEUR_PUBLIC: 2,

            IS_ADMIN: 1,
            IS_ADHERANT: 1,

            IS_PAIMENT_VALIDE: 1,
            IS_PAIMENT_NOT_VALIDE: 0,

            HOMME: 0,
            FEMME: 1,

            NEW_USER: 1,
            OLD_USER: 2,

            MARIE: 2,
            VEUF: 4,

            HTTP_VALDATION_ERROR: 422,
            HTTP_NOT_FOUND: 404,
            HTTP_SERVER_ERROR: 500,
            HTTP_OK: 200,
            HTTP_INVALID: 409,

            etats: {
                "2": "Réfusé",
                "1": "Accepté",
                "0": "En attente"
            },
            statut: {
                ACTIF: 1,
                ARRET_DACTIVITE: 2,
                RETRAITE: 3
            },

            GREEN_COLOR: "#52c41a",
            RED_COLOR: "#eb2f96",

            newAdherentsEntriesTimeout: null,
            newDemandesAttestationEntriesTimeout: null,
            newDemandesOuverturesEntriesTimeout: null,
            newMessagesEntriesTimeout: null,
            userNewMessagesEntriesTimeout: null,
            dateFormat: "DD/MM/YYYY",
            formLayout: "horizontal"
        };
    }
});
//--------------------------------- VUE ---------------------------------

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");