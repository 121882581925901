var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.isPayeAjour(_vm.balance)
      ? _c("span", [
          _vm._v(
            "\n    Vous êtes à jour jusqu'à l'année " +
              _vm._s(_vm.nextYearExercice) +
              "\n  "
          )
        ])
      : _vm.exercices.length > 0
      ? _c("span", [
          _vm._v(
            "\n    Vous devez régulariser vos cotisations pour la période\n    "
          ),
          _c("b", { style: { color: _vm.RED_COLOR } }, [
            _vm._v(" " + _vm._s(_vm.exercices) + ". ")
          ])
        ])
      : _c("span", [
          _vm._v(
            "\n    Vous êtes à jour mais vous avez des dettes à régler\n  "
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }