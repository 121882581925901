const COTISATION_URL = `/paiement`;
const COTISATION_FILE_URL = `/upload-paiement-documents`;
const CALCULTE_USER_BAALNCE_URL = `/calculate-user-balance`;
const VALIDATE_PAIEMENT_URL = `/admin/user/paiement/validate`;
const PAIEMENT_A_JOUR_URL = `/user/paiement/set-dernier-paiement-a-jour`;

import get from "lodash-es/get";
import reduce from "lodash-es/reduce";

export default {
  state: {
    cotisations: [],
    balance: null,
    selected_paiement: null,
    new_paiements_count : null,
    cotisations_history: [],
    default_pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
    pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
  },
  getters: {
    getCotisations(state) {
      return state.cotisations;
    },
    getNewPaiementsCount(state) {
      return state.new_paiements_count;
    },
    getCotisationsById(state) {
      return (id) => {
        return state.cotisations.find((cotisation) => cotisation.id === id);
      };
    },
    getUserBalanceValue(state) {
      return get(state, 'balance.balance', 0);
    },
    getUserBalanceExercicesARegler(state) {
      return get(state, 'balance.exercices', null);
    },
    getCotisationPagination(state) {
      return {
        per_page: state.pagination.pageSize,
        page: state.pagination.current,
      };
    },
    getPaiementsSomme(state) {
      return reduce(
        state.cotisations,
        function(sum, paiement) {
          return sum + paiement.montant;
        },
        0
      );
    },
    existingAdminCotisation(state) {
      return state.pagination.total > 0 ? true : false;
    },
  },
  mutations: {
    setCotisations(state, data) {
      state.cotisations = data;
    },
    setNewPaiementsCount(state, count){
      state.new_paiements_count = count;
    },
    clearUserBalance(state) {
      state.balance = null;
    },
    setSelectedPaiement(state, data) {
      state.selected_paiement = data;
    },
    clearSelectedPaiement(state) {
      state.selected_paiement = null;
    },
    setUserBalance(state, data) {
      state.balance = data;
    },
    setHistoryCotisations(state, data) {
      state.cotisations_history = data;
    },
    setCotisationPagination(state, data) {
      state.pagination = data;
    },
    setCotisationDefaultPagination(state) {
      state.pagination = state.default_pagination;
    },
    setCotisationPaginationFromServer(state, data) {
      state.pagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
  },
  actions: {
    async fetchAdminPaiements({ dispatch, commit, getters }, payload) {
      try {
        let para = {
          ...payload,
          ...getters.getCotisationPagination,
        };
        return await dispatch(
          "fetchAdminCotisationsWithoutSettingState",
          para
        ).then((response) => {
          commit("setCotisations", response.data.data);
          commit("setCotisationPaginationFromServer", response.data.meta);
        });
      } catch (e) {
        throw e;
      }
    },
    async storePaiement({ getters }, paiement) {
      try {
        return await getters.authenticatedAxios.post(COTISATION_URL, paiement);
      } catch (e) {
        throw e;
      }
    },
    async setUserPaiementPassif({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.post(
          `${PAIEMENT_A_JOUR_URL}/${payload.userId}`, {...payload});
      } catch (e) {
        throw e;
      }
    },
    async destroyPaiement({ getters }, id) {
      try {
        return await getters.authenticatedAxios.delete(
          `${COTISATION_URL}/${id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async fetchPaiementById({ getters, commit }, id) {
      try {
        return await getters.authenticatedAxios
          .get(`${COTISATION_URL}/${id}`)
          .then((response) => commit("setSelectedPaiement", response.data));
      } catch (e) {
        return e;
      }
    },

    async fetchNewPaiementsCountCount({ dispatch, commit }) {
      try {
        return await dispatch("enqueueToCounter", {
          key: "new_paiements_count",
          resolver: (data) => {
            commit("setNewPaiementsCount", data);
          },
        });
      } catch (e) {
          throw e;
      }
    },
    async updatePaiement({ getters }, paiement) {
      try {
        return await getters.authenticatedAxios.put(
          `${COTISATION_URL}/${paiement.id}`,
          paiement
        );
      } catch (e) {
        throw e;
      }
    },
    async calculateUserBalance({ commit, getters }, id) {
      try {
        return await getters.authenticatedAxios
          .get(`${CALCULTE_USER_BAALNCE_URL}/${Number(id)}`)
          .then((response) => commit("setUserBalance", response.data));
      } catch (e) {
        throw e;
      }
    },
    async validatePaiement({ getters }, paiement) {
      try {
        return await getters.authenticatedAxios.put(
          `${VALIDATE_PAIEMENT_URL}/${paiement.id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyPaiementFile({ getters }, id) {
      try {
        return await getters.authenticatedAxios.delete(
          `${COTISATION_FILE_URL}/${Number(id)}`
        );
      } catch (e) {
        throw e;
      }
    },
    async fetchAdminCotisationsWithoutSettingState({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.get(`${COTISATION_URL}`, {
          params: {
            ...payload,
          },
        });
      } catch (e) {
        throw e;
      }
    },
  },
};
