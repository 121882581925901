<template>
  <span>
    <span :style="{ color: getStatutsColor() }">
      <a-icon
        v-if="!!statutProfessionnel"
        :type="getStatutsIcon()"
        theme="twoTone"
        :twoToneColor="getStatutsColor()"
        class="margin_right"
      />{{ getStatutsName() }}
    </span>
  </span>
</template>
<script>
import { mapGetters } from "vuex";
import _find from "lodash-es/find";
export default {
  name: "StatutProfessionnelIndicator",
  props: { statutProfessionnel: [Number, String] },
  methods: {
    ...mapGetters({ getStatutsArray: "getStatuts" }),
    getStatutsName() {
      if (!this.statutProfessionnel) {
        return;
      }
      return this.getStatuts(this.statutProfessionnel).nom_fr;
    },
    getStatutsColor() {
      if (!this.statutProfessionnel) {
        return;
      }
      return this.getStatuts(this.statutProfessionnel).color;
    },
    getStatutsIcon() {
      if (!this.statutProfessionnel) {
        return;
      }
      return this.getStatuts(this.statutProfessionnel).icon;
    },
    getStatuts(s) {
      return _find(this.getStatutsArray(), { id: s });
    },
  },
};
</script>
