const EMAILS_URL = `/email`;
const EMAILS_FILE_URL = `/email/file`;
const USERS_EMAILS_URL = `/admin/emails-list`;
const PAID_USERS_EMAILS_URL = `/admin/paid-users-names-list`;
const MARK_AS_READ_URL = `/email/mark-as-read`;
const RECEIVED_MESSAGES_URL = `/email/received`;

import moment from "moment";
import remove from "lodash-es/remove";

export default {
  state: {
      received_messages: [],
      sent_messages: [],
      message: null,
      unread_messages_count: null,
      users_emails: [],
      paid_users_emails: [],
      uploaded_attachements_file_ids: [],
      selected_tab_key: 1,
      default_pagination: {
          current: 1,
          total: 0,
          pageSize: 7,
      },
      received_messages_pagination: {
          current: 1,
          total: 0,
      pageSize: 7,
    },
    sent_messages_pagination: {
      current: 1,
      total: 0,
      pageSize: 7,
    },
  },
  getters: {
    getSentMessasges(state) {
      return state.sent_messages;
    },
    getReceivedMessasges(state) {
      return state.received_messages;
    },
    getAttachementsIds(state) {
      return state.uploaded_attachements_file_ids;
    },
    getTotalUnreadMessages(state) {
      return state.unread_messages_count;
    },
    getSelectedTabKey(state) {
      return state.selected_tab_key;
    },
    getReceivedMessasgesPagination(state) {
      return {
        per_page: state.received_messages_pagination.pageSize,
        page: state.received_messages_pagination.current,
      };
    },
    getSentMessasgesPagination(state) {
      return {
        per_page: state.sent_messages_pagination.pageSize,
        page: state.sent_messages_pagination.current,
      };
    },
    getAdherentIdByEmail(state) {
      return (email) => {
        return state.users_emails.find(
          (user_email) => user_email.email === email
        ).id;
      };
    },
  },
  mutations: {
      setUsersEmails(state, data) {
          // Sort from the newest to oldest
          state.users_emails = data;
      }, setPaidUsersEmails(state, data) {
          // Sort from the newest to oldest
          state.paid_users_emails = data;
      },
      setUnreadMessagesCount(state, count) {
          state.unread_messages_count = count;
      },
      addAttachementsFileId(state, data) {
          state.uploaded_attachements_file_ids.push(data);
      },
      setSentMessages(state, data) {
          // Sort from the newest to oldest
      state.sent_messages = data.sort((a, b) => {
        return !moment(a.dt).unix() - moment(b.dt).unix();
      });
    },
    setReceivedMessages(state, data) {
      state.received_messages = data;
    },
    setSelectedMessage(state, data) {
      state.message = data;
    },
    setSelectedTabKey(state, data) {
      state.selected_tab_key = data;
    },
    clearSelectedMessage(state) {
      state.message = null;
    },
    setSentMessagesPagination(state, data) {
      state.sent_messages_pagination = data;
    },
    setReceivedMessagesPagination(state, data) {
      state.received_messages_pagination = data;
    },
    setReceivedPaginationFromServer(state, data) {
      state.received_messages_pagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
    setSentPaginationFromServer(state, data) {
      state.sent_messages_pagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
    clearAttachedFilesId(state) {
      state.uploaded_attachements_file_ids = [];
    },
    removeAttachedFileId(state, data) {
      remove(state.uploaded_attachements_file_ids, function(id) {
        return id === data;
      });
    },
  },
  actions: {
    async fetchUnreadMessagesCount({ commit, dispatch }) {
      try {
        return await dispatch("enqueueToCounter", {
          key: "new_messages_entries",
          resolver: (data) => {
            commit("setUnreadMessagesCount", data);
          },
        });
      } catch (e) {
          throw e;
      }
    },
      async fetchUsersEmails({getters, commit}) {
          try {
              return await getters.authenticatedAxios
                  .get(USERS_EMAILS_URL)
                  .then((response) => commit("setUsersEmails", response.data));
          } catch (e) {
              throw e;
          }
      },
      async fetchPaiedUsersEmails({getters, commit}) {
          try {
              return await getters.authenticatedAxios
                  .get(PAID_USERS_EMAILS_URL)
                  .then((response) => commit("setPaidUsersEmails", response.data));
          } catch (e) {
              throw e;
          }
      },
      async sendMessage({getters}, payload) {
          try {
              return await getters.authenticatedAxios.post(EMAILS_URL, payload);
          } catch (e) {
              throw e;
          }
      },
      async fetchMessageById({getters, commit}, payload) {
      try {
        return await getters.authenticatedAxios
          .get(`${EMAILS_URL}/${payload.id}`)
          .then((response) => commit("setSelectedMessage", response.data));
      } catch (e) {
        throw e;
      }
    },
    async fetchSentMessages({ getters, commit }, payload) {
      try {
        let para = {
          ...getters.getSentMessasgesPagination,
        };
        return await getters.authenticatedAxios
          .get(`${EMAILS_URL}`, {
            params: {
              ...para,
              ...payload,
            },
          })
          .then((response) => {
            commit("setSentMessages", response.data.data);
            commit("setSentPaginationFromServer", response.data.meta);
          });
      } catch (e) {
        throw e;
      }
    },
    async fetchReceivedMessages({ getters, commit }, payload) {
      try {
        let para = {
          ...getters.getReceivedMessasgesPagination,
        };
        return await getters.authenticatedAxios
          .get(`${RECEIVED_MESSAGES_URL}`, {
            params: {
              ...para,
              ...payload,
            },
          })
          .then((response) => {
            // console.warn('fetching.......');
            commit("setReceivedMessages", response.data.data);
            commit("setReceivedPaginationFromServer", response.data.meta);
          });
      } catch (e) {
        throw e;
      }
    },
    async destroyAttachedFile({ getters, commit }, payload) {
      try {
        return await getters.authenticatedAxios
          .delete(`${EMAILS_FILE_URL}/${payload}`)
          .then(() => commit("removeAttachedFileId", payload));
      } catch (e) {
        throw e;
      }
    },
    async cleanSelectedMessage({ commit }) {
      commit("clearSelectedMessage");
    },
    async markMessageAsRead({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.put(
          `${MARK_AS_READ_URL}/${payload}`
        );
      } catch (e) {
        throw e;
      }
    },
  },
};
