const WILAYA_URL = `/wilaya`;
const ORGANISATION_COMMUNE_URL = `/organisation_commune`;
import toUpper from "lodash-es/toUpper";
import join from "lodash-es/join";

export default {
  state: {
    wilayas: [],
    organisation_wilayas: [],
    organisation_communes: [],
  },
  getters: {
    getWilayas(state) {
      return state.wilayas;
    },
    getOrganisationWilaysNames(state) {
      return join(
        state.organisation_wilayas.map((wilaya) => {
          return toUpper(wilaya.nom_fr);
        }),
        " - "
      );
    },
  },
  mutations: {
    setWilayas(state, data) {
      state.wilayas = data;
    },
    setOrganisationWilayas(state, data) {
      state.organisation_wilayas = data;
    },
    setOrganisationCommunes(state, data) {
      state.organisation_communes = data;
    },
  },
  actions: {
    async storeWilaya({ getters, commit }, Wilaya) {
      try {
        return await getters.authenticatedAxios
          .post(WILAYA_URL, Wilaya)
          .then((response) => commit("addWilaya", response.data));
      } catch (e) {
        throw e;
      }
    },
    async fetchWilayas({ dispatch, commit }) {
      try {
        return await dispatch("enqueue", {
          key: "wilayas",
          resolver: (data) => {
            commit("setWilayas", data);
          },
        });
      } catch (e) {
        throw e;
      }
    },
    async fetchOrganisationWilayas({ commit, dispatch }) {
      try {
        return await dispatch("enqueue", {
          key: "organisation_wilayas",
          resolver: (data) => {
            commit("setOrganisationWilayas", data);
          },
        });
      } catch (e) {
        throw e;
      }
    },
    async fetchOrganisationCommunes({ getters, commit }) {
      try {
        return await getters.authenticatedAxios
          .get(ORGANISATION_COMMUNE_URL)
          .then((response) => {
            commit("setOrganisationCommunes", response.data.data);
          });
      } catch (e) {
        throw e;
      }
    },
  },
};
