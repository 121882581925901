const URL = `/exercice`;

const IS_CURRENT_EXE = 1;

import first from "lodash-es/first";
import last from "lodash-es/last";

export default {
  state: {
    exercices: [],
    current_exercice: null,
  },
  getters: {
    getCurrentExercice(state) {
      return state.current_exercice;
    },
    getExercices(state) {
      return state.exercices;
    },
    getMinExerice(state) {
      return last(state.exercices);
    },
    getMaxExerice(state) {
      return first(state.exercices);
    },
  },
  mutations: {
    setAdminExecrcices(state, data) {
      state.exercices = data;
    },
    setAdminCurrentExecrcice(state, data) {
      state.current_exercice = data.find(
        (exercices) => exercices.is_current === IS_CURRENT_EXE
      );
    },
  },
  actions: {
    async fetchAdminExecrcices({ dispatch, commit }) {
      try {
        return await dispatch(
          "fetchAdminExecrcicesWithoutSettingState",
          URL
        ).then((response) => {
          commit("setAdminExecrcices", response.data.data);
          commit("setAdminCurrentExecrcice", response.data.data);
        });
      } catch (e) {
        throw e;
      }
    },

    async fetchExercices({ dispatch, commit }) {
      try {
        return await dispatch("enqueue", {
          key: "exercices",
          resolver: (data) => {
            commit("setAdminExecrcices", data);
            commit("setAdminCurrentExecrcice", data);
          },
        });
      } catch (e) {
        throw e;
      }
    },
    async fetchAdminExecrcicesWithoutSettingState({ getters }, url) {
      try {
        return await getters.authenticatedAxios.get(url);
      } catch (e) {
        throw e;
      }
    },
    async updateAdminExecrcices({ getters }, exercice) {
      try {
        return await getters.authenticatedAxios.put(
          `${URL}/${exercice.id}`,
          exercice
        );
      } catch (e) {
        throw e;
      }
    },
  },
};
