<template>
  <div>
    <h1 style="margin-bottom:4%">Etat des paiements</h1>
    <PaiementsListeWithFilters
      :dataSource="cotisations"
      :processing="processing"
      :excercices="excercices"
      :secteurs="secteurs"
      :wilayas="wilayas"
      :isAdmin="true"
      :modes_reglement="modes_reglement"
      :types_reglements="types_reglements"
      :somme="somme"
      @change="searchPaiement($event)"
      @exercice_change="handleExerciceChange($event)"
      @paiement_change="handlePaiementChange($event)"
      @type_reglement_change="handleTypeReglementChange($event)"
      @etat_secteur_activite_change="handleSecteurActviteChange($event)"
      @wilaya_change="handleWilayaChange($event)"
      @date_range_change="dateRangeChange($event)"
    ></PaiementsListeWithFilters>
    <a-pagination
      v-if="cotisations.length"
      style="margin-top:1%"
      @change="onPaginationChange"
      @showSizeChange="onPageSizeChange"
      :current="pagination.current"
      :total="pagination.total"
      :pageSize="pagination.pageSize"
      :showSizeChanger="true"
    />
  </div>
</template>

<script>
  import PaiementsListeWithFilters from "@/components/common/paiement/PaiementsListeWithFilters.vue";
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import moment from "moment";

  const FROM_ADMIN = 0;
  // const FROM_ADHERANT = 1;
  export default {
    name: "Index",
    created() {
      if (this.cotisations.length > 0)
        return;
      this.fetchData();
      this.getModesReglement();
      this.getSecteursActivites();
      this.checkForNewPaiementsEntries();
      this.$store.cache.dispatch("fetchAdminExecrcices");
      this.$store.cache.dispatch("fetchWilayas");
    this.$store.cache.dispatch("fetchTypePaiements");
  },
  components: {
    PaiementsListeWithFilters,
  },

  data() {
    return {
      selected_exercice: "",
      selected_mode_paiement: "",
      selected_secteur_activite: "",
      selected_type_reglement: "",
      selected_wilaya: "",
      from_date: "",
      to_date: "",
      q: "",
      newPaiementsEntriesTimeout: 13000,
      processing: false,
      FROM_ADMIN,
    };
  },
  watch: {
    selected_exercice: function(oldExercice, newExercice) {
      this.fetchData();
    },
    selected_mode_paiement: function(oldExercice, newExercice) {
      this.fetchData();
    },
    selected_secteur_activite: function(oldExercice, newExercice) {
      this.fetchData();
    },
    selected_wilaya: function(oldExercice, newExercice) {
      this.fetchData();
    },
    selected_type_reglement: function(oldExercice, newExercice) {
      this.fetchData();
    },
    from_date: function(oldExercice, newExercice) {
      this.fetchData();
    },
    to_date: function(oldExercice, newExercice) {
      this.fetchData();
    },
    q: function(oldExercice, newExercice) {
      this.fetchData();
    },
  },
  computed: {
    ...mapState({
      excercices: (state) => state.exercices.exercices,
      modes_reglement: (state) => state.mode_reglement.modes_reglement,
      types_reglements: (state) => state.type_paiements.type_paiements,
      cotisations: (state) => state.admin_paiements.cotisations,
      pagination: (state) => state.admin_paiements.pagination,
      secteurs: (state) => state.secteurs.secteurs,
      wilayas: (state) => state.wilayas.organisation_wilayas,
    }),
    ...mapGetters({
      somme: "getPaiementsSomme",
    }),
  },
  methods: {
    moment,
    getSecteursActivites() {
      this.$store.cache.dispatch("fetchSecteurs");
    },
    fetchData() {
      this.processing = true;
      this.getAdditioanlData(this.getPrams())
        .then(() => (this.processing = false))
        .catch(() => (this.processing = false));
    },
    getPrams() {
      return {
        exercice: this.selected_exercice,
        mode_paiement: this.selected_mode_paiement,
        secteur_activite: this.selected_secteur_activite,
        type_reglement: this.selected_type_reglement,
        wilaya_id: this.selected_wilaya,
        from : this.from_date,
        to : this.to_date,
        q: this.q,
      };
    },
    onPaginationChange(pagination, filters, sorter) {
      this.setPagination({ ...this.pagination, current: pagination });
      this.fetchData();
    },
    onPageSizeChange(current, size) {
      this.setPagination({ ...this.pagination, pageSize: size });
      this.fetchData();
    },
    searchPaiement(value) {
      this.setQuery(value);
    },
    handleExerciceChange(value) {
      this.setExerice(value);
    },
    handleTypeReglementChange(value){
      this.setTypeReglement(value);
    },
    handleSecteurActviteChange(value) {
      this.setSecteurActvite(value);
    },
    handlePaiementChange(value) {
      this.setPaiement(value);
    },
    handleWilayaChange(value) {
      this.setWilaya(value);
    },
    dateRangeChange(value) {
      if (!value.length) {
        this.from_date = "";
        this.to_date = "";
        return;
      }
      this.setFromDate(this.formatDate(value[0], "YYYY/MM/DD"));
      this.setToDate(this.formatDate(value[1], "YYYY/MM/DD"));
    },
    getModesReglement() {
      this.$store.cache.dispatch("fetchModesReglement", {
        sender: this.FROM_ADMIN,
      });
    },
    checkForNewPaiementsEntries() {
      if (!Window.newPaiementsEntriesTimeout) {
        Window.newPaiementsEntriesTimeout = setInterval(() => {
          this.getNewPaiementsCount();
        }, this.newPaiementsEntriesTimeout);
      }
    },
    setExerice(value) {
      this.selected_exercice = value;
    },
    setPaiement(value) {
      this.selected_mode_paiement = value;
    },   setTypeReglement(value) {
      this.selected_type_reglement = value;
    },
    setSecteurActvite(value) {
      this.selected_secteur_activite = value;
    },
    setWilaya(value) {
      this.selected_wilaya = value;
    },
    setFromDate(value) {
      this.from_date = value;
    },
    setToDate(value) {
      this.to_date = value;
    },
    setQuery(value) {
      this.q = value;
    },
    ...mapActions({
      getAdditioanlData: "fetchAdminPaiements",
      getModesReglement: "fetchModesReglement",
      search: "getAdditioanlData",
      getNewPaiementsCount: "fetchNewPaiementsCountCount",
    }),
    ...mapMutations({
      resetPagination: "setCotisationDefaultPagination",
      setPagination: "setCotisationPagination",
    }),
  },
};
</script>
