var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "span",
      { style: { color: _vm.getStatutsColor() } },
      [
        !!_vm.statutProfessionnel
          ? _c("a-icon", {
              staticClass: "margin_right",
              attrs: {
                type: _vm.getStatutsIcon(),
                theme: "twoTone",
                twoToneColor: _vm.getStatutsColor()
              }
            })
          : _vm._e(),
        _vm._v(_vm._s(_vm.getStatutsName()) + "\n  ")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }