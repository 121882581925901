import Axios from "axios";
import get from "lodash-es/get";

const PRINT_URL = process.env.VUE_APP_PDF_GENRATOR_URL;
const DOWNLOAD_MODE = `1`;
const TYP_AJOUR = 50;
const TYP_NON_AJOUR = 51;
const TYP_GLOBAL = 52;

export default {
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async callPdfGenerator({}, payload) {
      try {
        return await Axios.get(
          `${PRINT_URL}${get(payload, "id", 1)}&${
            payload.type
          }&${DOWNLOAD_MODE}&${payload.organisation}&${get(
            payload,
            "id_demande",
            0
          )}&${get(payload, "exercice", 1)}`,
          { responseType: "blob" }
        ).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "document.pdf");
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        throw e;
      }
    },

    async printShownList({ dispatch, rootGetters }, payload) {
      const rapports = rootGetters.getTypeRapports
      const types = {0: TYP_GLOBAL, 1: TYP_AJOUR, 2: TYP_NON_AJOUR};
      const type = types[payload.selected_paiement_value || 0];
      const rapport = rapports.filter(r => r.type == type)[0];
      if (rapport)
        return await dispatch("callPdfGenerator", {
          id: payload.ids.join(","),
          type: rapport.id,
          exercice: payload.exercice,
          organisation: payload.organisation,
        });
      throw new Error('Erreur à l\'impression, modèle non trouvé !');
    },
  },
};
